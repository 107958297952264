import has from 'lodash/has'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

function generateAdSlot({ adUnit, uid, height, width, scnid, sctids, scsids, articletypes }) {
  if (typeof window !== 'undefined' && has(window, 'googletag')) {
    window.googletag = window.googletag || { cmd: [] }
    window.ypAdslots = window.ypAdslots || []

    window.googletag.cmd.push(function () {
      const types = isEmpty(articletypes) ? 'DEFAULT' : articletypes
      let slot = false

      if (width === 300) {
        //Show multi height if width 300px
        slot = window.googletag.defineSlot(
          adUnit,
          [
            [width, height],
            [width, 350],
          ],
          uid,
        )
      } else {
        slot = window.googletag.defineSlot(adUnit, [width, height], uid)
      }

      if (slot) {
        window.ypAdslots.push({
          uid,
          slot,
        })
      }

      slot
        .addService(window.googletag.pubads())
        // Add targeting for the nid, section, topics, and articletypes tids.
        .setTargeting('scnid', scnid) // set key-value targeting for the article nid
        .setTargeting('sctid', sctids) // topic tids, can be array ["503302", "503303"]
        .setTargeting('scsid', scsids) // section tids
        .setTargeting('articletype', types) // article type tids, can be array ["503302", "503303"], if no article type, set string "DEFAULT"
      window.googletag.enableServices()
      window.googletag.display(uid)
    })
  }
}

function destroyAdSlots() {
  if (typeof window !== 'undefined' && has(window, 'googletag') && has(window, 'ypAdslots')) {
    const slots = map(window.ypAdslots, 'slot')
    window.ypAdslots = []
    window.googletag.destroySlots(slots)
  }
  return true
}

export { destroyAdSlots, generateAdSlot }
