import ScriptManager from 'lib/script-manager'
import get from 'lodash/get'
import { useStore } from 'store'

const withGooglePubTag = (WrappedComponent) => {
  const WithGooglePubTag = (props) => {
    const namespace = 'googlepubtag'
    const { externalScriptsState } = useStore()
    const { loaded = false } = get(externalScriptsState, `state[${namespace}]`, {})

    const setScriptStatus = (loaded) => {
      const script = {}
      script[namespace] = { loaded }
      externalScriptsState.dispatch(script)
    }

    const options = {
      namespace,
      url: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js',
      isDefer: false,
      isAsync: true,
    }

    ScriptManager.loadScript(options).then((loaded) => {
      if (loaded) {
        setScriptStatus(loaded)
      }
    })

    return <WrappedComponent scriptLoaded={loaded} {...props} />
  }
  return WithGooglePubTag
}

export default withGooglePubTag
