import get from 'lodash/get'
import set from 'lodash/set'

const ScriptManager = {
  scriptStatus(namespace) {
    const initStatus = { loaded: false, loading: false }
    return typeof window !== 'undefined' ? get(window, `[${namespace}]`, initStatus) : initStatus
  },
  loadScript({ namespace, url, isDefer = false, isAsync = true }) {
    return new Promise((resolve) => {
      // Prevent repeatedly adding scripts
      if (typeof window !== 'undefined') {
        const isLoading = get(window, `[${namespace}].loading`)
        const isLoaded = get(window, `[${namespace}].loaded`)

        if (!(isLoading || isLoaded)) {
          // Start to load
          set(window, `[${namespace}].loading`, true)

          const newScript = document.createElement('script')
          newScript.onload = () => {
            set(window, `[${namespace}].loading`, false)
            set(window, `[${namespace}].loaded`, true)
            resolve(true)
          }
          newScript.onerror = (err) => {
            set(window, `[${namespace}].loading`, false)
            set(window, `[${namespace}].loaded`, false)
            console.log('Error loading script: ', err)
            resolve(false)
          }
          newScript.async = isAsync
          newScript.defer = isDefer
          document.head.appendChild(newScript)
          newScript.src = url
        } else {
          resolve(false)
        }
      } else {
        resolve(false)
      }
    })
  },
}

export default ScriptManager
