import { nodeProcess } from './node-process'

const nodeHandler = (node = {}) => {
  const { entityUuid } = node
  if (entityUuid) {
    const originalNode = {}
    const processedNode = {}

    originalNode[entityUuid] = node
    // unfreeze the object before processing
    processedNode[entityUuid] = nodeProcess(JSON.parse(JSON.stringify(node)))

    return { originalNode, processedNode }
  }

  return {}
}

export default nodeHandler
