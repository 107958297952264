import { useQuery } from '@apollo/client'
import { GET_ARTICLE_BY_ENTITY_UUID, GET_ARTICLE_BY_ENTITY_UUID_LITE } from 'apollo/queries/cs'
import nodeHandler from 'utils/node/node-handler'

const useFetchArticle = (entityUuid, authHeader = '', mode = 'lite') => {
  const previewMode = process.env.PREVIEW_MODE === '1'

  const QUERY = mode === 'lite' ? GET_ARTICLE_BY_ENTITY_UUID_LITE : GET_ARTICLE_BY_ENTITY_UUID

  const { data: { article = {} } = {} } = useQuery(QUERY, {
    variables: {
      entityUuid,
    },
    notifyOnNetworkStatusChange: true,
    ssr: true,
    context: {
      headers:
        previewMode && authHeader !== ''
          ? {
              Authorization: authHeader,
            }
          : {},
    },
  })
  return nodeHandler(article)
}

export default useFetchArticle
