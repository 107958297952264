import compact from 'lodash/compact'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import has from 'lodash/has'
import includes from 'lodash/includes'
import map from 'lodash/map'
import startsWith from 'lodash/startsWith'

import { APP_PREFIX } from '../../config/app.config'

const getBlockquoteText = (data) => {
  const { children } = data

  const blockQuoteText = map(children, (child) => {
    const { type } = child
    if (type === 'blockquote-quote') {
      const { type, data: blockquoteQuoteChildrenData } = get(child, 'children[0]', {})
      if (type === 'text') return blockquoteQuoteChildrenData
      return false
    }
  })

  return compact(blockQuoteText)
}

const nodeProcess = (node) => {
  if (node) {
    const extraData = {
      // blockquote: []
    }
    const { body, authors = [] } = node
    forEach(body, (data) => {
      const { type } = data
      switch (type) {
        case 'blockquote':
          if (!has(extraData, 'blockquote')) extraData.blockquote = []
          extraData.blockquote.push(getBlockquoteText(data))
          break
      }
    })
    node.extraData = extraData

    // handle author path
    map(authors, (author) => {
      if (includes(author.types, 'junior_reporter') && author.urlAlias) {
        author.urlAlias = ''
      } else {
        if (startsWith(author.urlAlias, '/')) {
          author.urlAlias = author.urlAlias.replace(new RegExp(`^${APP_PREFIX}/`), '/')
          author.urlAlias = `https://scmp.com${author.urlAlias}`
        }
      }
      return author
    })
  }

  node.contentLock = node.contentLock === false ? false : true
  node.isSponsorArticle = !!node.sponsorType || !!get(node, 'ypTopics.[0].sponsor.name')

  return node
}

module.exports = {
  nodeProcess,
}
