import withGooglePubTag from 'hocs/with-google-pub-tag'
import withLazyloadLogic from 'hocs/with-lazyload-logic'
import { generateAdSlot } from 'lib/advert'
import flowRight from 'lodash/flowRight'
import uniqueId from 'lodash/uniqueId'
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { color, remCalc } from 'styles/mixins'

import { GPT_AD_NETWORK_ID, GPT_AD_SITE_NAME } from '../../config/app.config'

const StyledAdWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  width: ${(props) => `${props.adWidth}px`};
  min-height: ${(props) => `${props.adHeight}px`};
  margin-left: auto;
  margin-right: auto;
`

const StyledAdSlot = styled.div`
  width: ${(props) => `${props.adWidth}px`};
  min-height: ${(props) => `${props.adHeight}px`};

  ${(props) =>
    props.isTerm &&
    css`
      padding: ${remCalc(14)};
      background: ${color.black07};
    `}
`

const AdvertSlot = ({
  className,
  width,
  height,
  advertZone,
  scriptLoaded,
  slot,
  scnid,
  sctids,
  scsids,
  articletypes,
  isTerm,
  isHidden,
}) => {
  const adZone = advertZone || 'youngpost'
  const [uid] = useState(uniqueId('div-gpt-ad-'))

  const adUnit = `/${[GPT_AD_NETWORK_ID, GPT_AD_SITE_NAME, adZone, slot].join('/')}`

  // Because the element id and the uid keep changing and is different,
  // to ensure the ad lib getting the correct element id
  // I need to use ref here to get the id
  const createAd = () => {
    if (scriptLoaded && !isHidden && uid) {
      generateAdSlot({ uid, width, height, adUnit, scnid, sctids, scsids, articletypes })
    }
  }
  // gen ad slot
  useEffect(createAd, [scriptLoaded, isHidden])

  return (
    <StyledAdWrapper className={className} adWidth={width} adHeight={height}>
      {scriptLoaded && !isHidden && (
        <StyledAdSlot
          key={'advert'}
          adWidth={width}
          adHeight={height}
          id={uid}
          isTerm={isTerm}
          className={'advert-ad-slot'}
        />
      )}
    </StyledAdWrapper>
  )
}

AdvertSlot.defaultProps = {
  width: 300,
  height: 250,
  isHidden: false,
}

AdvertSlot.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  slot: PropTypes.string,
  advertZone: PropTypes.string.isRequired,
  scriptLoaded: PropTypes.bool,
  isHidden: PropTypes.bool,
}

export default flowRight([withLazyloadLogic, withGooglePubTag])(AdvertSlot)
