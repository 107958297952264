import has from 'lodash/has'
import { useEffect } from 'react'

import { FB_APP_ID } from '../config/app.config'

const withFacebookLogic = (WrappedComponent) => {
  const WithFacebookLogic = (props) => {
    useEffect(() => {
      if (typeof window !== 'undefined' && !has(window, 'fbAsyncInit')) {
        const init = (d, s, id) => {
          const fjs = d.getElementsByTagName(s)[0]
          if (d.getElementById(id)) {
            return
          }
          const js = d.createElement(s)
          js.id = id
          js.src = 'https://connect.facebook.net/en_US/sdk.js'
          fjs.parentNode.insertBefore(js, fjs)
        }
        window.fbAsyncInit = () => {
          window.FB.init({
            appId: FB_APP_ID,
            xfbml: true,
            version: 'v2.12',
          })
        }
        init(document, 'script', 'facebook-jssdk')
      }
    }, [])
    return <WrappedComponent {...props} />
  }
  return WithFacebookLogic
}

export default withFacebookLogic
