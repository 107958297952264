import includes from 'lodash/includes'
import React from 'react'

const Element = (props) => {
  const { children, type } = props

  const allowedTypes = [
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'strong',
    'em',
    'u',
    'span',
    's',
    'div',
    'p',
    'ul',
    'li',
    'ol',
    'table',
    'tr',
    'td',
    'tbody',
  ]

  const elementType = includes(allowedTypes, type) ? type : 'span'

  return React.createElement(elementType, props, [children])
}

export default Element
