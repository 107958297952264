import Lazyload from 'components/Common/LazyLoad'

const withLazyloadLogic = (WrappedComponent) => {
  const WithLazyloadLogic = (props) => {
    const { width, height, className, attribs: { width: attWidth, height: attHeight } = {} } = props

    return (
      <Lazyload className={className} width={width || attWidth} height={height || attHeight}>
        <WrappedComponent {...props} />
      </Lazyload>
    )
  }
  return WithLazyloadLogic
}

export default withLazyloadLogic
