import { format } from 'date-fns'
import differenceInHours from 'date-fns/differenceInHours'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import isValid from 'date-fns/isValid'

function articleDateFormat(date, withTime = true) {
  // This format criteria:
  // 1. Within 1 hour ago, show minutes ago
  // 2. Within 6 hours, show hours ago
  // 3. Default show 11:01am, 03 March, 2020
  if (isValid(date)) {
    const diffInMins = differenceInMinutes(new Date(), date)
    const diffInHours = differenceInHours(new Date(), date)
    if (diffInMins > 0 && diffInMins <= 60) {
      const minuteText = diffInMins > 1 ? 'mins' : 'min'
      return `${diffInMins} ${minuteText} ago`
    }
    if (diffInHours > 0 && diffInHours <= 6) {
      const hourText = diffInHours > 1 ? 'hours' : 'hour'
      return `${diffInHours} ${hourText} ago`
    }
    return format(date, withTime ? 'h:mmaaa, d MMM, yyyy' : 'd MMM, yyyy')
  }
  return false
}

export { articleDateFormat }
