import cloneDeep from 'lodash/cloneDeep'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import transform from 'lodash/transform'
import unset from 'lodash/unset'

const richTextRenderer = ({
  type,
  children,
  data,
  attribs,
  componentRenderer,
  mode = 'html',
  showAdvert,
}) => {
  const attribsBlackList = (attrs) => {
    const blacklist = ['style']
    const proprocessedAttrs = cloneDeep(attrs)
    forEach(blacklist, (blacklistItem) => {
      unset(proprocessedAttrs, blacklistItem)
    })
    return proprocessedAttrs
  }

  const renderedChildrenArray = isEmpty(children)
    ? [data]
    : transform(
        children,
        (sum, child) => {
          const nestedType = get(child, 'type')
          const nestedChildren = get(child, 'children')
          const nestedAttribs = attribsBlackList(get(child, 'attribs'))
          const nestedData = get(child, 'data')
          if (!isEmpty(nestedChildren)) {
            return sum.push(
              componentRenderer({
                type: nestedType,
                children: map(nestedChildren, (nestedChild) =>
                  richTextRenderer({ ...nestedChild, componentRenderer }),
                ),
                attribs: nestedAttribs,
                mode,
                showAdvert,
              }),
            )
          } else {
            return sum.push(
              componentRenderer({
                type: nestedType,
                children: [nestedData],
                attribs: nestedAttribs,
                mode,
                showAdvert,
              }),
            )
          }
        },
        [],
      )
  return componentRenderer({
    type,
    children: renderedChildrenArray,
    attribs: attribsBlackList(attribs),
    mode,
    showAdvert,
  })
}

export { richTextRenderer }
