import { format } from 'date-fns'
import isValid from 'date-fns/isValid'
import { articleDateFormat } from 'lib/formatter/date'
import PropTypes from 'prop-types'

const CustomDate = ({ className, date, withTime = true }) => {
  const DateObject = new Date(date)
  return isValid(DateObject) ? (
    <time className={className} dateTime={format(DateObject, 'yyyy-MM-dd')}>
      {articleDateFormat(DateObject, withTime)}
    </time>
  ) : (
    <span></span>
  )
}

CustomDate.propTypes = {
  date: PropTypes.number,
  withTime: PropTypes.bool,
}

export default CustomDate
