import useFetchArticle from 'hooks/hook-fetch-article'
import get from 'lodash/get'
import { useMemo } from 'react'

const withArticleLevel = (WrappedComponent) => {
  return (props) => {
    const { entityUuid, entity } = props

    const article = useFetchArticle(entityUuid)
    const node = useMemo(() => {
      return entity || get(article, `processedNode[${entityUuid}]`, {})
    }, [entity, article])

    return <WrappedComponent {...props} entityUuid={node.entityUuid} node={node} />
  }
}

export default withArticleLevel
