import has from 'lodash/has'
import { useEffect } from 'react'

const withTwitterLogic = (WrappedComponent) => {
  return (props) => {
    useEffect(() => {
      if (has(window, 'twttr.widgets.load')) {
        window.twttr.widgets.load()
      } else if (!has(window, 'twttr.widgets')) {
        // Script provided by twitter.
        /* eslint-disable */
        window.twttr = (function (d, s, id) {
          var js,
            fjs = d.getElementsByTagName(s)[0],
            t = window.twttr || {}
          if (d.getElementById(id)) return t
          js = d.createElement(s)
          js.id = id
          js.src = 'https://platform.twitter.com/widgets.js'
          fjs.parentNode.insertBefore(js, fjs)

          t._e = []
          t.ready = function (f) {
            t._e.push(f)
          }

          return t
        })(document, 'script', 'twitter-wjs')
        /* eslint-enable */
      }
    }, [])
    return <WrappedComponent {...props} />
  }
}

export default withTwitterLogic
