import merge from 'lodash/merge'
import range from 'lodash/range'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import 'intersection-observer' // polyfill
import { InView } from 'react-intersection-observer'
import styled, { css } from 'styled-components'
import { color } from 'styles/mixins'

const StyledSvg = styled.svg`
  display: block;
  max-width: 100%;
  ${(props) =>
    props.responsive &&
    css`
      height: 100%;
    `}
`

const StyledRect = styled.rect`
  fill: ${color.muddywhite};
`

const DefaultPlaceholder = ({ width, height, responsive }) => {
  const cWidth = width || '100%'
  const cHeight = height || '100%'

  return (
    <StyledSvg responsive={responsive} width={width} height={height}>
      <StyledRect width={cWidth} height={cHeight} />
    </StyledSvg>
  )
}

const LazyLoad = ({
  width,
  height,
  className,
  children,
  options,
  inViewCallback,
  outViewCallback,
  triggerOnce = true,
  showChildren = false,
  placeholder = false,
  responsive = false,
}) => {
  const showPlaceholder = !showChildren
  const [showComponent, setShowComponent] = useState(showChildren)
  const intersectionConfig = merge(
    {},
    {
      triggerOnce,
      rootMargin: '0px',
      threshold: range(0, 1, 0.1),
    },
    options,
  )

  const onInViewChanged = (inView) => {
    if (inView) {
      inViewCallback()
      if (!showComponent) {
        setShowComponent(true)
      }
    } else {
      outViewCallback()
    }
  }

  const PlaceHolder = () => {
    return (
      placeholder || <DefaultPlaceholder responsive={responsive} width={width} height={height} />
    )
  }

  const output = true && showComponent ? children : showPlaceholder ? PlaceHolder() : false

  return (
    <InView className={className} {...intersectionConfig} as="span" onChange={onInViewChanged}>
      {output}
    </InView>
  )
}

LazyLoad.defaultProps = {
  inViewCallback: () => {},
  outViewCallback: () => {},
}

LazyLoad.propTypes = {
  inViewCallback: PropTypes.func,
  outViewCallback: PropTypes.func,
}

export default LazyLoad
